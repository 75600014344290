import Navbar from './components/Navbar/Navbar';
import About from './components/About/About';
import Landing from './components/Landing/Landing';
import Mision from './components/Mission/Mission';
import Services from './components/Services/Services';
import Contact from './components/Contact/Contact'
import { useRef } from 'react'
import './fonts/BerkshireSwash/BerkshireSwash-Regular.ttf'
import './fonts/Montserrat/Montserrat-VariableFont_wght.ttf'
import './_base.scss'

function App() {

  const scrollToLanding = useRef(null)
  const scrollToAbout = useRef(null)
  const scrollToServices = useRef(null)
  const scrollToContact = useRef(null)
  const scrollToMission = useRef(null)

  const scrollFunctions = {
    goToLanding: () => scrollToLanding.current.scrollIntoView(),
    goToAbout: () => scrollToAbout.current.scrollIntoView(),
    goToServices: () => scrollToServices.current.scrollIntoView(),
    goToContact: () => scrollToContact.current.scrollIntoView(),
    goToMission: () => scrollToMission.current.scrollIntoView(),
  }

  return (
    <div className='App'>
      <Navbar scrollFunctions={scrollFunctions}/>
      <Landing scrollTo={scrollToLanding} />
      <Mision scrollTo={scrollToMission}/>
      <Services scrollTo={scrollToServices}/>
      <About scrollTo={scrollToAbout}/>
      <Contact scrollTo={scrollToContact}/>
    </div>
  );
}

export default App;
