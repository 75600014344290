import React from 'react'
import './Services.scss'

const Services = ({ scrollTo }) => {
  
  return (
    <div ref={scrollTo} className='slide services'>
      
      {/* Contenedor del título */}
      <div className='services-title-container'>
        <div className='services-title-decors'></div>
        <h1 className='services-title'>Áreas de Práctica</h1>
        <div className='services-title-decors'></div>
      </div>
      
      {/* Contenedor de servicios */}
      <div className='services-container'>

        {/* Contenedor de servicios de familia */}
        <div className='service-container' id='familyservice'>
            <div className='service-img-container'>
              <h2 className='service-img-title'>Derecho de Familia</h2>
            </div>

            <p className='service-text'>
              El Derecho de Familia requiere de un estudio particular y sensible sobre la materia.
              En esta área, se ofrece asesoría y representación en:
            <ul>
              <li>Cuidado Personal, Pensión Alimenticia y Relación Directa y Regular.</li>
              <li>Divorcio, Separación, Nulidad y Compensación Económica.</li>
              <li>Declaración y Desafectación de Bienes Familiares.</li>
              <li>Autorizaciones de Salida del País y Cambio de Residencia.</li>
              <li>Medidas de Protección frente a Vulneración de Derechos.</li>
              <li>Violencia Intrafamiliar.</li>
              <li>Adopción.</li>
            </ul>
          </p>
        </div>

        {/* Contenedor de servicios de sucesorio */}
        <div className='service-container' id='sucesoryservice'>
            <div className='service-img-container'>
              <h2 className='service-img-title'>Derecho Sucesorio</h2>
            </div>
            <p className='service-text'>
              El Derecho Sucesorio se encuentra muy vinculado con la protección del grupo familiar, siendo de gran relevancia su asesoría y planificación.
              En esta área, se ofrece asesoría y representación en:
              <ul>
                <li>Planificación Sucesoria.</li>
                <li>Elaboración de Testamentos.</li>
                <li>Procedimientos de apertura de Testamentos y Posesión Efectiva.</li>
                <li>Juicios de Partición.</li>
              </ul>
            </p>
        </div>
      </div>
    </div>
  )
}

export default Services