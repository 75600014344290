import React from 'react'
import { useState } from 'react'

import './Navbar.scss'
import navbarLogo from '../../img/logo-white.png'


const Navbar = ({ scrollFunctions }) => {

  const [showNavlinks, setShowNavLinks] = useState(false);

  const toggleMenu = () => {
    setShowNavLinks(!showNavlinks)
    /* console.log("Changed to ->", showNavlinks) */
  }

  const goToLinkAndCloseNavbar = (scrollFunction) => {
    toggleMenu()
    scrollFunction()
  }

  return (
    <div className='navbar'>
      <div className='logo-burger-container'>
        {/* Logo de la navbar */}
        <img src={navbarLogo} alt='navbar logo' className='navbar-logo'/>

        {/* Menú hamburguesa */}
        <div className='burger-menu-button' onClick={toggleMenu}>
          <div className='burger-line'></div>
          <div className='burger-line'></div>
          <div className='burger-line'></div>
        </div>  
      </div>

      {/* Navlinks / Lista de botones */}
      <div className={'navbar-links' + (showNavlinks ? ' show-links' : '') }>
        <button className='navbar-link' onClick={() => goToLinkAndCloseNavbar(scrollFunctions.goToLanding)}>INICIO</button>
        <button className='navbar-link' onClick={() => goToLinkAndCloseNavbar(scrollFunctions.goToMission)}>MISIÓN</button>
        <button className='navbar-link' onClick={() => goToLinkAndCloseNavbar(scrollFunctions.goToServices)}>SERVICIOS</button>
        <button className='navbar-link' onClick={() => goToLinkAndCloseNavbar(scrollFunctions.goToAbout)}>SOMOS</button>
        <button className='navbar-link' onClick={() => goToLinkAndCloseNavbar(scrollFunctions.goToContact)}>CONTACTO</button>
      </div>

    </div>
  )
}

export default Navbar