/* 
    Funciones de utilidad para el formulario de contacto
*/
const API_URL = 'https://api.vlabogadas.cl'

const validateFormContent = (formContent, setModalContent) => {
    /**
     * Validar contenido del formulario
     * 
     * Retorna true si campos son válidos
     * 
     * Setea modal con error correspondiente y retorna false
     * si contenido del form es invalido
     */
    
    // Primero vemos campos vacíos
    var isEmpty = []

    if (!formContent.nombre) {
      isEmpty.push('Nombre y Apellido')
    }
    if (!formContent.telefono) {
      isEmpty.push('Teléfono')
    }
    if (!formContent.correo) {
      isEmpty.push('Correo Electrónico')
    }
    if (formContent.servicio === 'Label') {
      isEmpty.push('Tipo de Servicio')
    }
    if (formContent.monto === 'Label') {
      isEmpty.push('Monto dispuesto a pagar.')
    }

    if (isEmpty.length > 0) {
      // Mostrar modal indicando campos vacíos
      setModalContent({
        show: true,
        title: 'Campos vacíos',
        content: 'Rellena al menos los siguientes campos vacíos antes de continuar:',
        button: 'Intentar nuevamente',
        list: isEmpty
      })
      // Retornar false para no seguir validando
      return false
    }

    
    // Si no hay campos vacíos, verificar formato del correo electrónico (verificación sencilla)
    let invalidEmail = false
    if (formContent.correo.split('@').length !== 2) {
        invalidEmail = true
    } else if (formContent.correo.split('@')[1].split('.').length < 2 ) {
        invalidEmail = true
    } else {
        formContent.correo.split('@')[1].split('.').forEach(
            elem => {
                if (!elem) {
                    invalidEmail = true
                }
            }
        )
    }

    if (invalidEmail) {
        setModalContent({
            show: true,
            title: 'Error',
            content: 'El formato del correo electrónico es inválido.',
            button: 'Intentar nuevamente',
            list: []
          })
        return false
    }

    // Retornar true si el contenido es válido
    return true


}

const sendFormRequest = async (formContent, setModalContent) => {
    /**
     * Enviar POST a la API para enviar correo
     * 
     * Luego de enviar correo, mostrar modal diciendo si el envio
     * de correo fue exitoso. Si no lo fue, mostrar modal diciendo
     * que lo intente otra vez o que contacte por redes sociales
     * mostradas en el fondo de la página
     */

    // Enviar POST
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "nombre_completo": formContent.nombre,
        "telefono": formContent.telefono,
        "correo_electronico": formContent.correo,
        "servicio": formContent.servicio,
        "mensaje": formContent.descripcion_servicio,
        "dispuesto_a_pagar": formContent.monto
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    try {
      let response = await fetch(API_URL + "/contact/submit", requestOptions)
      
      let json = await response.json()

      if (json.message === "Email enviado") {
        setModalContent({
          show: true,
          title: 'Solicitud de contacto enviada',
          content: 'Solicitud de contacto enviada con éxito. Recibirás en tu correo una confirmación del envio de este formulario (Si no ves el correo de confirmación, revisa tu carpeta de correo no deseado).',
          button: 'Confirmar',
          list: []
        })

        return
      } else { // En caso de error
        setModalContent({
          show: true,
          title: 'Error Desconocido.',
          content: 'No se pudo enviar la solicitud. Por favor contáctenos a través de los numeros y/o redes sociales indicadas en el pie de página.',
          button: 'Confirmar',
          list: []
        })
      }
    } catch(error) {
      console.log('error:', error)
      setModalContent({
        show: true,
        title: 'Error Desconocido.',
        content: 'No se pudo enviar la solicitud. Por favor contáctenos a través de los numeros y/o redes sociales indicadas en el pie de página.',
        button: 'Confirmar',
        list: []
      })
    }
}

export {
    validateFormContent,
    sendFormRequest
}