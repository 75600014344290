import React from 'react'
import './Mission.scss'

const Mission = ({ scrollTo }) => {
  return (
    <div ref={scrollTo} className='slide mision'>
      
      {/* Contenedor del título */}
      <div className='mision-title-container'>
        <div className='mision-title-decors'></div>
        <h1 className='mission-title'>Misión</h1>
        <div className='mision-title-decors'></div>
      </div>

      {/* Texto la sección */}
      <p className='mission-text'>
        Somos abogadas especializadas en el área de Derecho de Familia.
        Tenemos el compromiso de realizar un trabajo empático e integral,
        enfocándonos en la entrega de una respuesta a sus problemas, con el
        debido respeto por los estándares éticos que rigen el ejercicio de la profesión,
        a la dignidad humana y al interés superior de los Niños, Niñas y Adolescentes.
      </p>
    </div>
  )
}

export default Mission