import React from 'react'
import fullLogoWhite from '../../img/full-logo-white.png'
import landingArt from '../../img/landing-art.webp'

import './Landing.scss'

const Landing = ({scrollTo}) => {

  return (
    <div ref={scrollTo} className='slide landing'>
        {/* Logo en blanco y negro */}
        <img src={fullLogoWhite} alt='Logo Vergara y Leiva Abogadas' className='landing-logo'/>
        
        {/* Arte de la landing page */}
        <img src={landingArt} alt='Arte landing Page' className='landing-art'/>
    </div>
  )
}

export default Landing
