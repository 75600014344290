import React from 'react'
import './About.scss'

import kathy from '../../img/katherine.jpg'
import ignacia from '../../img/ignacia.jpg'
import { IoMdMail } from 'react-icons/io'
import { SiLinkedin  } from 'react-icons/si'
import { AiFillInstagram } from 'react-icons/ai'

const About = ({ scrollTo }) => {
  return (
    <div ref={scrollTo} className='slide about' >
        
        {/* Contenedor del título */}
        <div className='about-title-container'>
            <div className='about-title-decors'></div> 
            <h1 className='about-title'>Sobre Nosotras</h1>
            <div className='about-title-decors'></div>
        </div>
        
        {/* Contenedor de los perfiles */}
        <div className='profiles-container'>

            {/* Perfil Ignacia */}
            <div className='profile'>
                <img className='profilePic' src={ignacia} alt='profile-pic' />
                <div className='profile-info right'>
                    <p><b>Ignacia Vergara Caroca</b></p>
                    <p>
                        Abogada UC<br/>
                        Diplomado en Familia<br/>
                        Fundadora V&amp;L
                    </p>
                    <div className='profile-icons right'>
                        <a href='https://www.instagram.com/solamentejuridica/'>
                            <AiFillInstagram className='profile-icon' />
                        </a>
                        <a href='mailto:ievergara@vlabogadas.cl'>
                            <IoMdMail className='profile-icon'/>
                        </a>
                        <a href='https://www.linkedin.com/in/abogadaivc/'>
                            <SiLinkedin className='profile-icon profile-icon-linkedin'/>
                        </a>
                        
                    </div>
                </div>
            </div>

            {/* Perfil Katherine */}
            <div className='profile'>
                <img className='profilePic' src={kathy} alt='profile-pic' />
                <div className='profile-info'>
                    <p><b>Katherine Leiva Salinas</b></p>
                        <p>
                            Abogada UC<br/>
                            Diplomado en Familia<br/>
                            Fundadora V&amp;L
                        </p>
                    <div className='profile-icons'>
                        <a href='mailto:kleiva@vlabogadas.cl'>
                            <IoMdMail className='profile-icon'/>
                        </a>
                        <a href='https://www.linkedin.com/in/katherineleivasalinas/'>
                            <SiLinkedin className='profile-icon profile-icon-linkedin'/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default About