import React, { useState } from 'react'
import { validateFormContent, sendFormRequest } from './FormUtils'
import './Contact.scss'

import { SiLinkedin  } from 'react-icons/si'
import { IoMdMail } from 'react-icons/io'
import { BsWhatsapp } from 'react-icons/bs'


const Contact = ({ scrollTo }) => {

  const [formContent, setFormContent] = useState({
    nombre: '',
    correo: '',
    telefono: '',
    servicio: 'Label',
    descripcion_servicio: '',
    monto: 'Label'
  })

  const [modalContent, setModalContent] = useState({
    show: false,
    title: 'Titulo del modal',
    content: 'Contenido del modal',
    button: 'botón para cerrar modal',
    list: []
  })

  const [contactFormButtonText, setContactFormButtonText] = useState("Enviar Solicitud")

  const handleFormSubmit = async (event) => {
    event.preventDefault()
    let valid = validateFormContent(formContent, setModalContent)
    if (valid) {
      setContactFormButtonText("Enviando...")
      await sendFormRequest(formContent, setModalContent)
      setContactFormButtonText("Enviar Solicitud")
    }
  }

  return (
    <div ref={scrollTo} className='slide contact'>
        {/* Modal para indicar error */}
        <div className={'contact-modal-background' + (modalContent.show? '' : ' hide') }>
          <div className='contact-modal-container'>
            <div className='contact-modal-title'>
              <h3>{modalContent.title}</h3>
            </div>
            <div className='contact-modal-text'>
              <p>{modalContent.content}</p>
              <ul>
                {
                  modalContent.list.map(elem => {
                    return <li>{elem}</li>
                  })
                }
              </ul>
            </div>
            <button
              className='contact-form-button'
              onClick={() => setModalContent({...modalContent, show: false})}
              >{modalContent.button}
            </button>
          </div>
        </div>

        {/* Contenedor del título */}
        <div className='contact-title-container'>
          <div className='contact-title-decors'></div>
          <h1 className='contact-title'>Contacto</h1>
          <div className='contact-title-decors'></div>
        </div>

        {/* Formulario de contacto */}
        <div className='contact-form-container'>
          <p className='contact-form-text'>Puedes contactarnos enviando el siguiente formulario o a través de redes sociales indicadas en el pie de página. </p>

          <form className='contact-form'>
            {/* Nombre, Correo, Telefono, Servicio */}
            <div className='contact-form-row'>
              <div className='contact-form-column'>
                <input
                  type='text'
                  onChange={(event) => { 
                    setFormContent({...formContent, nombre: event.target.value})
                  }}
                  placeholder='Nombre y Apellido*' 
                  className='contact-text-input'
                  maxLength='50'/>
                  
                <input
                  type='email'
                  onChange={(event) => setFormContent({...formContent, correo: event.target.value})}
                  placeholder='Correo electrónico*'
                  className='contact-text-input'
                  maxLength='320'/>
              </div>
              <div className='contact-form-column'>
                <input
                  type='text'
                  onChange={(event) => setFormContent({...formContent, telefono: event.target.value})}
                  placeholder='Teléfono*'
                  className='contact-text-input'
                  maxLength='20'/>
                <select 
                  onChange={(event) => setFormContent({...formContent, servicio: event.target.value})}
                  defaultValue={"Label"} 
                  className='contact-form-select'>
                  <option value={"Label"} disabled>Seleccione tipo de Servicio*:</option>
                  <optgroup label="Derecho de Familia">
                    <option value={"D1"}>Cuidado Personal, Pensión Alimenticia y Relación Directa y Regular.</option>
                    <option value={"D2"}>Divorcio, Separación, Nulidad y Compensación Económica.</option>
                    <option value={"D3"}>Declaración y Desafectación de Bienes Familiares.</option>
                    <option value={"D4"}>Autorizaciones de Salida del País y Cambio de Residencia.</option>
                    <option value={"D5"}>Medidas de Protección frente a Vulneración de Derechos.</option>
                    <option value={"D6"}>Violencia Intrafamiliar.</option>
                    <option value={"D7"}>Adopción.</option>
                  </optgroup>
                  <optgroup label="Derecho Sucesorio">
                    <option value={"S1"}>Planificación Sucesoria.</option>
                    <option value={"S2"}>Elaboración de Testamentos.</option>
                    <option value={"S3"}>Procedimientos de apertura de Testamentos y Posesión Efectiva.</option>
                    <option value={"S4"}>Juicios de Partición.</option>
                  </optgroup>
                </select>
              </div>
            </div>

            {/* Descripción servicio y selección de monto */}
            <div className='contact-form-column2'>
              <textarea
                onChange={(event) => setFormContent({...formContent, descripcion_servicio: event.target.value})}
                placeholder='Descripción del servicio (opcional)'
                className='contact-text-input contact-form-description'
                maxLength='600'/>
              <select
                onChange={(event) => setFormContent({...formContent, monto: event.target.value})}
                defaultValue={"Label"}
                className='contact-form-select'>
                <option value={"Label"} disabled>Seleccione Monto dispuesto a pagar*:</option>
                <option value={"M1"}>$200.000 - $300.000</option>
                <option value={"M2"}>$300.000 - $500.000</option>
                <option value={"M3"}>$500.000 - $700.000</option>
                <option value={"M4"}>$700.000 - $900.000</option>
                <option value={"M5"}>&gt; $900.000</option>
                </select>
            </div>
            <div className='contact-form-button-container'>
              <button className='contact-form-button' onClick={handleFormSubmit}>{contactFormButtonText}</button>
            </div>
          </form>
        </div>

        {/* Footer con datos de contacto */}
        <div className='contact-footer-container'>
          <div className='contact-footer'>
            
            {/* Correo electrónico */}
            <div className='contact-data'>
              <IoMdMail className='contact-icon'/>
              <p><a className='contact-data-mail' href='mailto:contacto@vlabogadas.cl'>contacto@vlabogadas.cl</a></p>
            </div>

            {/* Números para Whatsapp */}
            <div className='contact-data'>
              <BsWhatsapp className='contact-icon'/>
              <div className='contact-data-numbers'>
                <a className='contact-data-number' href='https://wa.me/56994335307'>+56 9 94335307</a> <a className='contact-data-number' href='https://wa.me/56921911903'>+56 9 21911903</a>
              </div>
            </div>
          
            {/* Página de LinkedIn */}
            <div className='contact-data'>
              <SiLinkedin className='contact-icon'/>
              <p><a className='contact-data-linkedin' href='https://www.linkedin.com/company/vergara-leiva-abogadas/'>Vergara &amp; Leiva Abogadas</a></p>
            </div>

          </div>
        </div>

    </div>
  )
}

export default Contact
